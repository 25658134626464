@import '../../scss/utilities';

.highlights {
  @include make-row();
}

.highlights__header {
  @include make-col-ready();
  @include make-col(12);
  display: flex;
  justify-content: center;
  margin-bottom: 4.5rem;
  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
  }
}

.highlights__card {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-down(sm) {
    margin-bottom: $grid-gutter-width * 2;
  }
  @include media-breakpoint-only(md) {
    @include make-col(6);
  }
  @include media-breakpoint-only(lg) {
    @include make-col(5);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(4);
  }
}

.highlights__links {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-only(md) {
    @include make-col(6);
  }
  @include media-breakpoint-only(lg) {
    @include make-col(7);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(8);
  }
  &_half {
    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }
  }
}

.highlights__linksContainer {
  @include make-row();
}

.highlights__linkWrapper {
  flex: 0 0 100%;
  padding-bottom: $grid-gutter-width;
}

.highlights__link {
  @include make-col-ready();
  @include make-col(12);
  display: flex;
  @include media-breakpoint-only(md) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
  @include media-breakpoint-up(xl) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
  &_2up {
    &:nth-child(2) {
      .highlights__linkWrapper {
        padding-top: $grid-gutter-width;
        border-top: $ue-dashed-border;
      }
    }
  }
  &_4up {
    @include media-breakpoint-down(md) {
      &:nth-child(2) {
        .highlights__linkWrapper {
          padding-top: $grid-gutter-width;
          border-top: $ue-dashed-border;
        }
      }
      &:nth-child(3),
      &:nth-child(4) {
        display: none;
      }
    }
    @include media-breakpoint-up(lg) {
      @include make-col(6);
      &:nth-child(3),
      &:nth-child(4) {
        .highlights__linkWrapper {
          padding-top: $grid-gutter-width;
          border-top: $ue-dashed-border;
        }
      }
    }
  }
}

.highlights__art {
  display: none;
  @include media-breakpoint-up(lg) {
    @include make-col-ready();
    display: flex;
  }
  @include media-breakpoint-only(lg) {
    @include make-col(3);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(4);
  }
}

.highlights__footer {
  @include make-col-ready();
  @include make-col(12);
  display: flex;
  justify-content: center;
  margin: $grid-gutter-width 0;
  @include media-breakpoint-up(lg) {
    justify-content: flex-end;
  }
}
